<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      width="1300px"
      :ok-button-props="{ props: { loading: submitting } }"
      title="新增项目"
      @ok="handleSubmit"
    >
      <div class="custom-flex custom-justify-between set-background-box">
        <div class="set-background-box" style="-webkit-flex: 1;flex: 1;">
          <div>
            <a-form class="custom-nested-search-form" :form="searchForm">
              <a-row :gutter="24">
                <a-col :span="7" :xxl="4">
                  <a-form-item>
                    <a-input
                      v-decorator="['product_key', {
                        normalize: this.$lodash.trim
                      }]"
                      placeholder="请输入产品名称或项目代码或拼音码"
                      allow-clear
                      @change="changeProductKey"
                      autocomplete="off"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="4" :xxl="4">
                  <a-form-item label="类型">
                    <a-select
                      allow-clear
                      @focus="fetchProductTypes"
                      placeholder="请选择类型"
                      v-decorator="['product_type']"
                      @change="handleChangeProductType"
                    >
                      <a-select-option
                        v-for="productType in productTypes"
                        :key="productType.slug"
                        :value="productType.slug"
                      >
                        {{ productType.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :span="4" :xxl="4">
                  <a-form-item label="最大价格">
                    <a-input
                      v-decorator="['max_price']"
                      placeholder="请输入最大价格"
                      allow-clear
                      type="number"
                      @input="filterNum"
                      @change="changeMaxPrice"
                      autocomplete="off"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="4" :xxl="4">
                  <a-form-item label="最小价格">
                    <a-input
                      v-decorator="['min_price']"
                      placeholder="请输入最小价格"
                      allow-clear
                      type="number"
                      @input="filterNum"
                      @change="changeMinPrice"
                      autocomplete="off"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="3" :xxl="2">
                  <a-form-item>
                    <a-checkbox @change="displayChoose">显示已选</a-checkbox>
                  </a-form-item>
                </a-col>
                <a-col :span="2" :xxl="4">
                  <a-form-item>
                    已选{{ itemCount }}项
                  </a-form-item>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </div>

        <div class="custom-flex" style="background-color: #ff9e1c">
          <a-icon type="pay-circle" style="height: 35px;width: 35px;color: #ffffff;margin: 3px;fontSize:45px" />
          <div style="color:#ffffff;padding-left: 10px;">
            项目金额：
            <div class="set-price-font">{{ totalFee | formatCurrency }}元</div>
          </div>
        </div>
      </div>

      <a-table
        :columns="columns"
        :data-source="showProducts"
        :loading="loading"
        :pagination="false"
        :scroll="{ y: 350 }"
        row-key="id"
      >
        <div slot="specification" slot-scope="specification,record">
          <a-select
            @change="handleChangeSpec"
            style="width: 150px"
            v-model:value="record.default_spec_id"
          >
            <a-select-option
              v-for="spec in record.specification"
              :key="spec.id"
              :value="spec.id"
            >
              {{ spec.name }}
            </a-select-option>
          </a-select>
        </div>
        <div slot="count" slot-scope="count, record" class="custom-flex count-edit-box">
          <a-input-number :value="record.count" :min="0" :max="999" @change="(value) => changeCount(record,value)" />
          <span style="float: right;padding-top: 5px;">个</span>
          <a-icon v-if="record.benefit_the_people === false" type="plus-circle" theme="filled" style="padding-top: 5px;font-size: 20px;padding-left: 10px;color: #199cd5;" @click="plus(record)" />
          <a-icon v-if="record.benefit_the_people === false" type="minus-circle" theme="filled" style="padding-top: 5px;font-size: 20px;padding-left: 10px;color: #199cd5;" @click="minus(record)" />
        </div>
      </a-table>
      <a-form
        class="custom-flex-col custom-flex"
        :form="itemForm"
        :label-col="{ span: 7 }"
        :wrapper-col="{ span: 17 }"
        style="padding-top: 30px"
      >
        <a-row>
          <a-col :span="15">
            <a-form-item label="货品配货负责人" v-if="hasAssortingTask === true">
              <a-select
                v-decorator="['flower_room_user_id', {
                  initialValue: defaultAssortUser.id > 0 ? defaultAssortUser.id : undefined,
                  rules: [
                    { required: true, message: '请选择货品配货负责人' },
                  ]
                }]"
                placeholder="请选择货品配货负责人"
                @change="handleAssortingPhoneNumberChange"
              >
                <a-select-option
                  v-for="user in assortUserOptions"
                  :key="user.id"
                  :value="user.id"
                >
                  {{ user.emp_name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="花房派工负责人" v-if="hasDispatchingTask === true">
              <a-select
                v-decorator="['front_flower_user_id', {
                  initialValue: defaultDispatchUser.id > 0 ? defaultDispatchUser.id : undefined,
                  rules: [
                    { required: true, message: '请选择花房派工负责人' },
                  ]
                }]"
                placeholder="请选择花房派工负责人"
                @change="handleDispatchingPhoneNumberChange"
              >
                <a-select-option
                  v-for="user in dispatchUserOptions"
                  :key="user.id"
                  :value="user.id"
                >
                  {{ user.emp_name }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item label="厅房布置负责人" v-if="hasHallLayoutTask === true">
              <a-select
                v-decorator="['hall_clean_user_id', {
                  initialValue: defaultLayoutUser.id > 0 ? defaultLayoutUser.id : undefined,
                  rules: [
                    { required: true, message: '请选择厅房布置负责人' },
                  ]
                }]"
                placeholder="请选择厅房布置负责人"
                @change="handleHallPhoneNumberChange"
              >
                <a-select-option
                  v-for="user in layoutUserOptions"
                  :key="user.id"
                  :value="user.id"
                >
                  {{ user.emp_name }}
                </a-select-option>
              </a-select>
            </a-form-item>

            <a-form-item
              label="备注"
            >
              <a-textarea
                v-decorator="['remark', {
                  normalize: this.$lodash.trim,
                  rules: [
                    { max: 1000, message: '请输入备注，可在此描述挽联信息' },
                  ]
                }]"
                placeholder="请输入备注，可在此描述挽联信息"
              />
            </a-form-item>
          </a-col>
          <a-col :span="8">
            <a-form-item label="联系电话" v-if="hasAssortingTask === true">
              {{ distributionUserPhone }}
            </a-form-item>
            <a-form-item label="联系电话" v-if="hasDispatchingTask === true">
              {{ flowerUserPhone }}
            </a-form-item>
            <a-form-item label="联系电话" v-if="hasHallLayoutTask === true">
              {{ hallUserPhone }}
            </a-form-item>
          </a-col>

        </a-row>

      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { formatCurrency } from '@/utils/filter'
import { addAdditionalProduct, productOptions } from '@/api/order'
import { getProductType } from '@/api/product_type'
import { findTenantTaskUserOptions } from '@/api/user'

export default {
  name: 'AddItems',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    serviceOrderId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      itemForm: this.$form.createForm(this, { name: 'add_items' }),
      searchForm: this.$form.createForm(this, { name: 'add_items_search' }),
      submitting: false,
      loading: false,
      products: [],
      productTypes: [],
      selectedRowKeys: [],
      showChose: false,
      productKey: '',
      productTypeSlug: '',
      defaultDispatchUser: {},
      defaultLayoutUser: {},
      defaultAssortUser: {},
      assortUserOptions: [],
      dispatchUserOptions: [],
      layoutUserOptions: [],
      maxPrice: '',
      minPrice: '',
      selectedProduct: []
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    hasAssortingTask() {
      let hasAssorting = false
      this.products.forEach((res) => {
        if (res.task_type_slugs != null && res.task_type_slugs.includes('assorting') && res.count > 0) {
          hasAssorting = true
        }
      })
      return hasAssorting
    },
    hasDispatchingTask() {
      let hasDispatching = false
      this.products.forEach((res) => {
        if (res.task_type_slugs != null && res.task_type_slugs.includes('dispatching') && res.count > 0) {
          hasDispatching = true
        }
      })
      return hasDispatching
    },
    hasHallLayoutTask() {
      let hasHallLayout = false
      this.products.forEach((res) => {
        if (res.task_type_slugs != null && res.task_type_slugs.includes('hall_layout') && res.count > 0) {
          hasHallLayout = true
        }
      })
      return hasHallLayout
    },
    columns() {
      return [
        {
          title: '产品名称',
          width: 100,
          dataIndex: 'name'
        },
        {
          title: '项目代码',
          width: 90,
          dataIndex: 'code'
        },
        {
          title: '拼音码',
          width: 100,
          dataIndex: 'pinyin'
        },
        {
          title: '类型',
          width: 150,
          dataIndex: 'product_type_text'
        },
        {
          title: '规格',
          width: 200,
          dataIndex: 'default_spec_id',
          scopedSlots: { customRender: 'specification' }
        },
        {
          title: '价格',
          width: 150,
          dataIndex: 'default_spec_price_display'
        },
        {
          title: '订购个数',
          width: 150,
          dataIndex: 'count',
          scopedSlots: { customRender: 'count' }
        },
        {
          title: '金额(元)',
          width: 100,
          dataIndex: 'fee',
          customRender: formatCurrency
        }
      ]
    },
    totalFee() {
      let totalFee = 0
      this.products.forEach((product, index) => {
        totalFee = totalFee + product.count * product.default_spec_price
      })
      return totalFee
    },
    itemCount() {
      let count = 0
      this.products.forEach((product, index) => {
        if (product.count > 0) {
          count = count + 1
        }
      })
      return count
    },
    showProducts() {
      const productList = []
      this.products.forEach((product, index) => {
        if ((this.showChose === true && product.count > 0 || this.showChose === false) &&
            (this.productKey === '' || this.productKey != '' && (product.name.indexOf(this.productKey) > -1 || product.code.indexOf(this.productKey) > -1 || product.pinyin.indexOf(this.productKey) > -1)) &&
            ((this.productTypeSlug === '' || this.productTypeSlug === undefined) || this.productTypeSlug != '' && this.productTypeSlug != undefined && this.productTypeSlug === product.product_type_slug) &&
            (this.maxPrice === '' || this.maxPrice !== '' && product.default_spec_price <= parseFloat(this.maxPrice)) &&
            (this.minPrice === '' || this.minPrice !== '' && product.default_spec_price >= parseFloat(this.minPrice))) {
          productList.push(product)
        }
      })

      return productList
    }
  },
  created() {
    this.fetchData()
    this.fetchUserOptions()
  },
  methods: {
    filterNum() {
      const maxPrice = this.searchForm.getFieldValue('max_price')
      const minPrice = this.searchForm.getFieldValue('min_price')
      if (parseFloat(maxPrice) < 0) {
        this.searchForm.setFieldsValue({ max_price: 0 })
      }
      if (parseFloat(minPrice) < 0) {
        this.searchForm.setFieldsValue({ min_price: 0 })
      }
    },
    changeProductKey(e) {
      this.productKey = e.target.value
    },
    changeMaxPrice(e) {
      this.maxPrice = e.target.value
    },
    changeMinPrice(e) {
      this.minPrice = e.target.value
    },
    displayChoose() {
      this.showChose = !this.showChose
    },
    handleChangeProductType(e) {
      this.productTypeSlug = e
    },
    fetchProductTypes() {
      getProductType().then((res) => {
        this.productTypes = res.data
      })
    },
    fetchData() {
      productOptions(this.serviceOrderId).then((res) => {
        this.products = res.data
      })
    },
    plus(record) {
      record.count++
      record.fee = record.count * record.default_spec_price
      if (record.count > 0) {
        if (!this.selectedProduct.includes(record.id)) {
          this.selectedProduct.push(record.id)
        }
      }
    },

    minus(record) {
      if (record.count > 0) {
        record.count--
        record.fee = record.default_spec_price * record.count
        if (record.count <= 0) {
          let index = this.selectedProduct.indexOf(record.id);
          if (index !== -1) {
            this.selectedProduct.splice(index, 1);
          }
        }
      }
    },
    changeCount(record, count) {
      if (count <= 0) {
        count = 0
        let index = this.selectedProduct.indexOf(record.id);
        if (index !== -1) {
          this.selectedProduct.splice(index, 1);
        }
      }else {
        if (!this.selectedProduct.includes(record.id)) {
          this.selectedProduct.push(record.id)
        }
      }
      record.count = count
      record.fee = record.default_spec_price * record.count
    },
    handleSubmit() {
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.itemForm.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          console.log(values)
          console.log(this.products)
          let subProducts =[]
          for (let i = 0; i < this.selectedProduct.length; i++) {
            for (let j = 0; j < this.products.length; j++) {
              console.log(this.products[j],this.products[j].id,this.selectedProduct[i])
              if (this.products[j].id === this.selectedProduct[i]){
                subProducts.push(this.products[j])
              }
            }
          }
          console.log(subProducts)
          const editData = Object.assign({}, values, { products: subProducts })
          addAdditionalProduct(this.serviceOrderId, editData).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    },
    handleChangeSpec(specId) {
      this.products.forEach((product, index) => {
        product.specification.forEach((spec, num) => {
          if (spec.id === specId) {
            this.products[index].default_spec_id = specId
            this.products[index].default_spec_price = spec.price
            this.products[index].default_spec_price_display = spec.price_display
            this.products[index].fee = this.products[index].count * spec.price
          }
        })
      })
    },
    fetchUserOptions() {
      this.loadingAssortUserOptions = true
      this.loadingDispatchUserOptions = true
      this.loadingLayoutUserOptions = true
      findTenantTaskUserOptions({ task_type: 'assorting', order_type: 'service', order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.assortUserOptions = res.data.option_list
          this.defaultAssortUser = res.data.default_user
          this.distributionUserPhone = this.defaultAssortUser.phone_number
        }
        this.loadingAssortUserOptions = false
      })

      findTenantTaskUserOptions({ task_type: 'dispatching', order_type: 'service', order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.dispatchUserOptions = res.data.option_list
          this.defaultDispatchUser = res.data.default_user
          this.flowerUserPhone = this.defaultDispatchUser.phone_number
        }
        this.loadingDispatchUserOptions = false
      })

      findTenantTaskUserOptions({ task_type: 'hall_layout', order_type: 'service', order_id: this.serviceOrderId }).then((res) => {
        if (res.code === 0) {
          this.layoutUserOptions = res.data.option_list
          this.defaultLayoutUser = res.data.default_user
          this.hallUserPhone = this.defaultLayoutUser.phone_number
        }
        this.loadingLayoutUserOptions = false
      })
    },
    handleDispatchingPhoneNumberChange(e) {
      this.dispatchUserOptions.forEach((res) => {
        if (res.id === e) {
          this.flowerUserPhone = res.phone_number
        }
      })
    },
    handleAssortingPhoneNumberChange(e) {
      this.assortUserOptions.forEach((res) => {
        if (res.id === e) {
          this.distributionUserPhone = res.phone_number
        }
      })
    },
    handleHallPhoneNumberChange(e) {
      this.layoutUserOptions.forEach((res) => {
        if (res.id === e) {
          this.hallUserPhone = res.phone_number
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.count-edit-box {
  user-select: none;
}
</style>
